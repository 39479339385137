import {
    ConsentLevel,
    useConsentGiven,
    useFeature,
} from '@news-mono/web-common'
import React, { useState } from 'react'
import { useGdprMode } from '../../../../content/Video/utils/gdpr-mode'
import { Video } from '../../../../content/Video/Video'
import { PNArticleVideoProps } from './PNArticleVideo'
import {
    pnArticleVideoClassName,
    StyledPNArticleVideo,
    StyledPNArticleVideoContainer,
} from './PNArticleVideo.styled'
import { ConsentFallback } from '../../../../tracking/ConsentFallback/ConsentFallback'
import {
    responsiveComponent,
    ResponsiveProps,
} from '../../../../__helpers/responsive-component'
import {
    DimensionProps,
    withDimensions,
} from '../../../../__helpers/with-dimensions'
import { StyledPNFigcaption } from '../PNFigCaption/PNFigCaption.styled'

export type PropsWithResponsive = PNArticleVideoProps &
    ResponsiveProps &
    DimensionProps

const PNUnwrappedArticleVideo: React.FC<PropsWithResponsive> = (props) => {
    const [hideSticky, setHideSticky] = useState(false)

    const gdprMode = useGdprMode()
    const consentGiven = useConsentGiven({
        consentRequiredLevel: ConsentLevel.Advertising | ConsentLevel.Analytics,
    })
    const canLoadVideoPlayer = gdprMode || consentGiven

    const isMagniteEnabled = useFeature('magnite-header-tag-wrapper')

    const {
        byline,
        captionText,
        credit,
        kind = 'video',
        videoDetails,
        isHeroVideo,
        autoplayOptions,
        autoplayNextOptions,
        playButtonText,
        isVideoHub,
        publicUrl,
        publicationPathname,
        onPlayNextVideo,
        setVideoRendered,
    } = props

    if (!videoDetails.id) {
        return null
    }

    if (!canLoadVideoPlayer) {
        return <ConsentFallback />
    }

    const videoElement = (
        <Video
            ref={setVideoRendered}
            videoDetails={videoDetails}
            showTitles={props.showTitles}
            pauseOverlay={props.pauseOverlay}
            autoplayOptions={autoplayOptions}
            autoplayNextOptions={autoplayNextOptions}
            videoOptions={{
                ...props.videoOptions,
                compactMode: props.videoOptions.compactMode,
                isMagniteEnabled,
            }}
            innerRef={props.innerRef}
            getVideoQueue={props.getVideoQueue}
            onEvent={props.onEvent}
            pagePosition={props.isHeroVideo ? 'hero-video' : 'inline-article'}
            onUserInteraction={() => {}}
            showPlaylist={false}
            playButtonText={playButtonText}
            hideSticky={hideSticky}
            setHideSticky={setHideSticky}
            isVideoHub={isVideoHub}
            publicUrl={publicUrl}
            currentPath={publicationPathname}
            onPlayNextVideo={onPlayNextVideo}
            adElement={props.adElement}
            canAutoPlay={props.isHeroVideo}
        />
    )

    return (
        <StyledPNArticleVideo
            isHeroVideo={isHeroVideo}
            className={pnArticleVideoClassName}
        >
            <StyledPNArticleVideoContainer isHeroVideo={isHeroVideo}>
                {videoElement}
            </StyledPNArticleVideoContainer>

            {(captionText || credit || byline) && (
                <StyledPNFigcaption
                    byline={byline}
                    captionText={captionText}
                    credit={credit}
                    kind={kind}
                    shouldIndent={true}
                />
            )}
        </StyledPNArticleVideo>
    )
}

export const PNWebArticleVideo = responsiveComponent<PNArticleVideoProps>(
    withDimensions<PNArticleVideoProps & ResponsiveProps>(
        PNUnwrappedArticleVideo,
    ),
)
export default PNWebArticleVideo
